import $ from 'jquery';
import { permission } from 'App/permission/permission';

var Cookie = {
	init: function () {
		permission.get({
			type: 'cookie'
		});
	}
};

export { Cookie };
