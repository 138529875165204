/* app/ui/track */

var TrackLoad = {
	init: function () {
		if (document.querySelector('.js-track__umbraco-form')) {
			import(/* webpackChunkName: "TrackUmbracoForm" */ 'App/track/umbraco-form').then(({ Track: UmbracoForm }) => {
				UmbracoForm.init();
			});
		}

		if (document.querySelector('.js-track__ced-solution, .js-track__ced-website-link')) {
			import(/* webpackChunkName: "TrackCEDSolution" */ 'App/track/ced-solution').then(({ Track: CEDSolution }) => {
				CEDSolution.init();
			});
		}

		if (document.querySelector('.js-track__faq')) {
			import(/* webpackChunkName: "TrackFaq" */ 'App/track/faq').then(({ Track: Faq }) => {
				Faq.init();
			});
		}

		if (document.querySelector('.js-expand-item')) {
			import(/* webpackChunkName: "TrackExpand" */ 'App/track/expand-collapse').then(({ ExpandTrack: Expand }) => {
				Expand.init();
			});
		}
	}
};

export { TrackLoad };
