import { listen } from 'Util/core';
import { publish } from 'Util/pubsub';

import $ from 'jquery';

const selectors = Object.freeze({
	autocollapse: '.js-autocollapse',
});

const pubsubEvents = Object.freeze({
	close: '/expandcollapse/close',
});

export function init() {
	_initEvents();
}

function _initEvents() {
	document.addEventListener('click', _processClickEvent);
	listen(selectors.autocollapse, 'focusout', _processTooltipFocusOut);
}

/**
 * Check if the area is currently opened.
 * Currently assumes the "aria-expanded" attribute is being used for this.
 *
 * @param  {HTMLElement} $area [description]
 *
 * @return {boolean}
 */
function _isOpened($area) {
	const ariaExpanded = $area.getAttribute('aria-expanded');

	return ariaExpanded === 'true';
}

/**
 * If there are any open areas, and this click was not inside them,
 * close them.
 *
 * @param  {MouseEvent} e
 */
function _processClickEvent(e) {
	/** @type {NodeListOf<HTMLElement>} */
	const $areas = document.querySelectorAll(selectors.autocollapse);
	const $openAreas = Array.from($areas).filter(_isOpened);

	const $target = e.target;

	for (let $area of $openAreas) {
		if (!(
			e.target instanceof HTMLElement &&
			e.target.closest(selectors.autocollapse) === $area
		)) {
			_closeArea($area);
		}
	}
}

/**
 * Check if focus has left the currently open area.
 * If it has, close the area.
 *
 * @param  {FocusEvent} e
 */
function _processTooltipFocusOut(e) {
	// Make asynchronous so new activeElement can be detected
	window.setTimeout(() => {
		let $oldFocus = e.target;
		let $newFocus = document.activeElement;

		let $oldArea = $oldFocus.closest(selectors.autocollapse);
		let $newArea = $newFocus.closest(selectors.autocollapse);

		if ($oldArea !== $newArea) {
			_closeArea($oldArea);
		}
	}, 0);
}

/**
 * Close a given area
 *
 * @param  {HTMLElement} $area
 */
function _closeArea($area) {
	// Expand/Collapse expects the element to be in a jQuery wrapper
	const $jqueryArea = $($area);

	publish(pubsubEvents.close, $jqueryArea);
}
