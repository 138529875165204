import $ from 'jquery';

var selectors = Object.freeze({
	trigger: '.js-clipboard-copy',
	icon: '.js-clipboard-icon',
	value: 'data-clipboard-value',
	active: 'is-copied'
});

var Clipboard = {
	init: function () {
		$(selectors.trigger).on('click', Clipboard._processClick);
	},

	_processClick: function(event) {
		event.preventDefault();

		var $trigger = $(this);
		var value = $trigger.attr(selectors.value);
		navigator.clipboard.writeText(value);

		$trigger.attr('disabled', 'disabled');

		if ($trigger.find(selectors.icon)) {
			$trigger.find(selectors.icon).removeClass('iconf-link').addClass('iconf-tick');
		}

		var revert = function() {
			$trigger.removeAttr('disabled');
			$trigger.find(selectors.icon).removeClass('iconf-tick').addClass('iconf-link');
		};
		window.setTimeout(revert, 800);


		//console.log('Copied: ' + value);
	}
};

export { Clipboard };
