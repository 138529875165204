import $ from 'jquery';
import { activate } from 'Util/activate';
import { publish } from 'Util/pubsub';

/* Confirm v2.0.0 */

var confirm = (function () {
	var selectors = {
		yes: '.js-confirm__yes',
		no: '.js-confirm__no',
		close: '.js-confirm__close',

		modal: '.js-modal[id]'
	};

	var dataSelectors = {
		yes: 'confirm-yes',
		no: 'confirm-no',
		close: 'confirm-close'
	};

	var events = {
		modalOpen: '/modal/open',
		modalClose: '/modal/close'
	};

	var module = {
		check: function ($el, yes, no) {
			// Shows an element, and binds callbacks to "yes" and "no" buttons

			if (!$el.jquery) {
				$el = $($el);
			}

			module._show($el);
			module._bindEvents($el, yes, no);
		},

		_getModalId: function ($el) {
			var modalId = false;

			if ($el.is(selectors.modal)) {
				modalId = $el.attr('id');
			}

			return modalId;
		},

		_show: function ($el) {
			var modalId = module._getModalId($el);

			if (publish && modalId) {
				publish(events.modalOpen, modalId);
			} else {
				$el.show();
			}
		},

		_hide: function ($el) {
			var modalId = module._getModalId($el);

			if (publish && modalId) {
				publish(events.modalClose, modalId);
			} else {
				$el.hide();
			}
		},

		_bindEvents: function ($el, yes, no) {
			var close = module._buttonClick($.noop, $el);
			yes = module._buttonClick(yes, $el);
			no = module._buttonClick(no, $el);

			module._unbindEvents($el);

			$el.on(activate.event, selectors.yes, yes);
			$el.on(activate.event, selectors.no, no);
			$el.on(activate.event, selectors.close, close);

			$el.data(dataSelectors.yes, yes);
			$el.data(dataSelectors.no, no);
			$el.data(dataSelectors.close, close);
		},

		_unbindEvents: function ($el) {
			var yes = $el.data(dataSelectors.yes);
			var no = $el.data(dataSelectors.no);
			var close = $el.data(dataSelectors.close);

			if (yes) {
				$el.off(activate.event, selectors.yes, yes);
				$el.data(dataSelectors.yes, null);
			}
			if (no) {
				$el.off(activate.event, selectors.no, no);
				$el.data(dataSelectors.no, null);
			}
			if (close) {
				$el.off(activate.event, selectors.close, close);
				$el.data(dataSelectors.close, null);
			}
		},

		_buttonClick: function (callback, $el) {
			return activate(function (e) {
				e.preventDefault();

				module._hide($el);
				module._unbindEvents($el);

				if (callback) {
					callback.apply(this, arguments);
				}
			});
		}
	};

	return {
		check: module.check
	};

})();

export { confirm };
